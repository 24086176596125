import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const SearchIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootSearchIcon, className);

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.4744 27.5893L16.0385 17.1534C15.2052 17.8628 14.2469 18.4119 13.1635 18.8008C12.0802 19.1897 10.9915 19.3841 9.89748 19.3841C7.22804 19.3841 4.9688 18.4601 3.11977 16.6121C1.27072 14.7641 0.346191 12.5061 0.346191 9.83806C0.346191 7.17006 1.27021 4.91031 3.11823 3.0588C4.96626 1.2073 7.22427 0.281555 9.89227 0.281555C12.5603 0.281555 14.8201 1.20607 16.6715 3.0551C18.523 4.90412 19.4488 7.16338 19.4488 9.83285C19.4488 10.991 19.2436 12.1117 18.8334 13.195C18.4231 14.2783 17.8847 15.2046 17.218 15.9739L27.6539 26.4098L26.4744 27.5893ZM9.89748 17.7175C12.109 17.7175 13.976 16.9563 15.4984 15.4338C17.0209 13.9114 17.7821 12.0444 17.7821 9.83285C17.7821 7.62132 17.0209 5.75434 15.4984 4.23189C13.976 2.70945 12.109 1.94822 9.89748 1.94822C7.68595 1.94822 5.81897 2.70945 4.29652 4.23189C2.77411 5.75434 2.0129 7.62132 2.0129 9.83285C2.0129 12.0444 2.77411 13.9114 4.29652 15.4338C5.81897 16.9563 7.68595 17.7175 9.89748 17.7175Z" fill="#1C1B1F"/>
    </svg>
    

  );
};

const { string } = PropTypes;

SearchIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default SearchIcon;
